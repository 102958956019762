// Modules
import React from "react";
import { graphql } from "gatsby";

// Components
import Layout from "../../components/layout";
import Seo from "../../components/seo/seo";
import Hero from "../../components/hero";
import HeroRedesigned from "../../components/heroRedesigned";
import PageBuilder from "../../components/pageBuilder";
import Form from "../../components/form";

// Styles
import * as styles from "./landingPage.module.scss";
import * as blogStyles from "../blogsStyles.module.scss";

const LandingPage = ({ data: { sanityLandingPage } }) => {
  const { pageBuilder, pageHero } = sanityLandingPage.tabs._rawContent;
  const content = sanityLandingPage?._rawTabs?.content;
  const seo = sanityLandingPage.tabs?._rawSeo;
  const heroTitle = content.pageHero.heroText.titleText;
  const cta = content?.cta;

  const HeroComponent = pageHero?.isRedesigned ? HeroRedesigned : Hero;

  return (
    <Layout isLanding>
      <Seo title={heroTitle} {...seo} />
      <HeroComponent
        imageClassName={blogStyles.heroImg}
        image={pageHero.bgImage}
        subTitle={pageHero?.heroText.subtitleText}
        title={pageHero.heroText.titleText}
      />
      <div className={styles.mainContentWrapper}>
        <div className={styles.builderWrapper}>
          <PageBuilder pageBuilderContents={pageBuilder} />
        </div>
        <div className={styles.formContainer}>
          <Form
            {...content.form}
            className={styles.landingFormWrapper}
            anchor={content?.anchor}
          />
        </div>
      </div>
      {cta && <Form {...cta} />}
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    sanityLandingPage(tabs: { content: { slug: { current: { eq: $slug } } } }) {
      tabs {
        _rawSeo(resolveReferences: { maxDepth: 10 })
        _rawContent(resolveReferences: { maxDepth: 10 })
      }
      _rawTabs(resolveReferences: { maxDepth: 10 })
    }
  }
`;

export default LandingPage;
